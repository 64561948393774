/*
 * @Description: 
 * @Version: 2.0
 * @Autor: lvxiaopeng
 * @Date: 2024-08-20 16:49:56
 * @LastEditors: lvxiaopeng
 * @LastEditTime: 2024-08-21 13:24:51
 */
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
import "@hview/hview-style";
import hview from "@hview/hview-vue3";
// import VForm3 from '../vform/dist/designer.umd';
// import '../vform/dist/designer.style.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// @ts-ignore
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import dictionary from "@config/dictionary";
import DictCache from "@hview/hview-utils/lib/cache/dict";

DictCache.setDict(dictionary);
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(hview).use(ElementPlus, {
    locale: zhCn
}).use(router).mount('#app');
//.use(VForm3)
// router.isReady().then(() =>app.mount('#app'));
