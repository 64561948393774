import { RouteRecordRaw } from 'vue-router'

const chat: Array<RouteRecordRaw> = [{
    path: '/chat',
    meta: {
        title: "聊天"
    },
    component: () => import("@/views/chat/chat.vue")
}, {
    path: '/chat/common-words',
    meta: {
        title: "常用语管理",
        activeMenu: "/chat"
    },
    component: () => import("@/views/chat/common-words-list.vue")
}];
export default chat;